import 'babel-polyfill';
import React from 'react';
import Layout from '../components/templates/Layout';
import Webform from '../components/organisms/Webform/Webform';
import {graphql} from "gatsby";

const webformPage = ({data}) => (
    <Layout
        lang={data.drupal.webform.langcode}
        class={'contact'}
        activeMenu={'contact'}
        metatags={data.drupal.webform.url.metatags}
        title={data.drupal.webform.title}
    >

    <Webform
        nodeContent={data.drupal.webform}
        webformId={data.drupal.webform.webformRawField.list[0].targetId != null && (data.drupal.webform.webformRawField.list[0].targetId)}
    />
    </Layout>
);

export default webformPage;

export const query=graphql`
query webform($id: ID!, $language: Drupal_Langcode!) {
    drupal {
        webform: entityById(id: $id, langcode: $language, entityType: NODE) {
            ... on Drupal_NodeWebform {
                nid
                title
                body
                webformRawField {
                    list {
                        targetId
                    }
                } 
                path {
                    alias
                }
                ... on Drupal_NodeWebform {
                    webformRawField {
                        list {
                            targetId
                            entity {
                                label
                            }
                        }
                    }
                }
                langcode
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }

            }
        }
    }
}
`;
