import 'core-js/es6/array';
import 'core-js/es6/object';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/es6/set';
import 'core-js/es6/map';
import 'babel-polyfill';
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import '../../atoms/FormFields/Alert/Alert.scss';
import '../../atoms/FormFields/Label/Label.scss';
import '../../atoms/FormFields/InputText/InputText.scss';
import '../../atoms/FormFields/InputSelect/InputSelect.scss';
import '../../atoms/FormFields/InputControl/InputControl.scss';
import './Webform.scss';
import Header from "../../atoms/header/header";

// @TODO: refactor axios.
const axios = require('axios');

class Webform extends React.Component {
  /**
   * Define props.
   * @returns Object
   */
  static get propTypes() {
    return {
      webformId: PropTypes.string,
    };
  }

  /**
   * Define default props.
   * @returns Object
   */
  static get defaultProps() {
    return {
      webformId: '',
    };
  }

  /**
   * Component mounted.
   */
  componentDidMount() {
    this.setState({
      schema: {},
      uiSchema: {},
      csrfToken: '',
      externalErrors: [],
      formData: {},
      formSuccess: false,
      buttons: [{ value: 'Verzend' }],
    });
    if (this.props.webformId.length > 0) {
      this.getWebform(this.props.webformId);
    }
  }

  /**
   * Constructor.
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      schema: {},
      uiSchema: {},
      csrfToken: '',
      externalErrors: [],
      formData: {},
      formSuccess: false,
      buttons: [{ value: 'Verzend' }],
    };
  }

  /**
   * Get webform from API and store it in state.
   * @param webformId
   */
  getWebform(webformId) {
    const webformEnpoint = `${process.env.DRUPAL_URL}/webform_jsonschema/${webformId}`;
    axios.get(webformEnpoint).then((response) => {
      this.setState({
        schema: response.data.schema,
        uiSchema: response.data.ui,
        csrfToken: response.data.csrfToken,
        buttons: response.data.buttons,
      });
    }).catch((error) => {
      console.log(error);
    }).then(() => {
    });
  }

  /**
   * Handle form submit.
   */
  submitHandler = (form) => {
    if (form.errors.length === 0) {
      const webformSubmitEndpoint = `${process.env.DRUPAL_URL}/webform_jsonschema/${this.props.webformId}`;
      axios({
        method: 'post',
        url: webformSubmitEndpoint,
        data: form.formData,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.state.csrfToken,
        },
      }).then((response) => {
        if (response.data.saved === true) {
          this.setState({
            formSuccess: true,
          });
        }
      }).catch((error) => {
        const errors = [];
        error.response.data.errors.forEach((err) => {
          errors.push({
            name: err.path[0],
            message: err.userMessage,
          });
        });
        this.setState({
          externalErrors: errors,
        });
      }).then(() => {
      });
      this.setState({
        formData: form.formData,
      });
    }
  };

  /**
   * TODO: refactor/bugfix.
   * @param errors
   * @returns {*}
   */
  transformErrors = (errors) => {
    if (errors === undefined || errors.length === 0) {
      errors = this.state.externalErrors;
    }
    return errors;
  };

  /**
   * Render implementation.
   * @returns {string}
   */
  render() {
    let body = '';
    if (this.props.nodeContent.body !== undefined && this.props.nodeContent.body !== null) {
      body = this.props.nodeContent.body
    }
    return (
      <>
        <Header
            page_title={this.props.nodeContent.title}
            intro={''}
        >{body}</Header>

        {this.state.formSuccess === false
          ? <>
            <Form
              // liveValidate
              name={this.props.webformId}
              schema={this.state.schema}
              uiSchema={this.state.uiSchema}
              formData={this.state.formData}
              onSubmit={this.submitHandler}
              className="webform"
              transformErrors={this.transformErrors}
            >
              <div>
                <button type="submit" className={'btn btn-info'}>{this.state.buttons[0].value}</button>
              </div>
            </Form>
            </>
          : <div color="success">Hartelijk dank voor uw bericht. U ontvangt zo snel mogelijk een reactie.</div>
        }
      </>
    );
  }
}

export default Webform;
